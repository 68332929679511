<script lang="ts">
    import Container from '../atoms/Container.svelte'
    import Heading from '../atoms/Heading.svelte'
    import { concatClasses } from '../utils'

    let classNames = ''
    export { classNames as class }
    export let headingClass = ''
    export let size: 'sm' | '' = ''

    $: classes = concatClasses(['hero', size ? `hero-${size}` : '', classNames])
</script>

<header class={classes} {...$$restProps}>
    <Container>
        {#if $$slots.preheading}
            <Heading class="mb-3" level="6" useDiv>
                <slot name="preheading" />
            </Heading>
        {/if}
        <Heading
            class="hero-heading {headingClass}"
            decoration
            level="1"
            size="1"
        >
            <slot name="heading" />
        </Heading>

        <slot name="content" />
    </Container>
</header>

<style global lang="postcss">
    .hero {
        padding-top: theme('spacing.12');
        padding-bottom: theme('spacing.12');
        color: theme('colors.gray-600');
        background-color: theme('colors.white');

        @media screen(sm) {
            padding-top: theme('spacing.16');
            padding-bottom: theme('spacing.16');
        }

        @media screen(md) {
            padding-top: theme('spacing.20');
        }
    }

    .hero.hero-sm {
        padding-top: theme('spacing.12');
        padding-bottom: 0;
    }

    .hero-heading {
        margin-bottom: theme('spacing.6');
        line-height: 1.2;
        color: var(--primary-600, theme('colors.gray-600'));

        @media screen(sm) {
            margin-bottom: theme('spacing.8');
        }

        span {
            color: var(--primary-500, theme('colors.gray-500'));
        }
    }

    .page-frame .play-button svg {
        width: clamp(3rem, 2.545rem + 7.27vw, 6rem);
    }
</style>
